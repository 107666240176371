import { Stack, Step, StepLabel, Stepper, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React, { memo } from 'react';
import DefaultGrow from '../CustomComponents/DefaultGrow';

function MigrationStep(props, ref) {
   const { index, appear, stepType = '' } = props;

   const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

   const steps = ['Setup', 'Demo Migration', 'Full Migration', 'Completed'];
   const recentSteps = ['Full Migration', 'Confirmation', 'Recent Migration', 'Completed'];
   const smartSteps = ['Full Migration', 'Confirmation', 'Smart Update', 'Completed'];

   const realStep = stepType === 'recent' ? recentSteps : stepType === 'smart' ? smartSteps : steps;

   return (
      <DefaultGrow appear={appear}>
         <Stack alignItems="center">
            <Box sx={{ width: mdUp ? '700px' : '100%', px: 4 }}>
               <Stepper activeStep={index}>
                  {realStep.map((step) => (
                     <Step key={step}>
                        <StepLabel>{step}</StepLabel>
                     </Step>
                  ))}
               </Stepper>
            </Box>
         </Stack>
      </DefaultGrow>
   );
}

export default memo(MigrationStep);
