import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout as DashboardLayout } from 'src/layouts/dashboard';
import ConfirmMigration from 'src/pages/dashboard/MigrationStep/ConfirmMigration';
import DemoRunning from 'src/pages/dashboard/MigrationStep/DemoRunning';

const HomePage = lazy(() => import('src/pages/dashboard/index'));

// My things
const CreateMigration = lazy(() => import('src/pages/dashboard/CreateMigration'));
const Configuration = lazy(() => import('src/pages/dashboard/MigrationStep/Configuration'));
const DemoMigration = lazy(() => import('src/pages/dashboard/MigrationStep/DemoMigration'));
const FullMigration = lazy(() => import('src/pages/dashboard/MigrationStep/FullMigration'));
const CompleteMigration = lazy(() => import('src/pages/dashboard/MigrationStep/CompleteMigration'));
const MyProfile = lazy(() => import('src/pages/dashboard/MyProfile'));
const MigrationDetail = lazy(() => import('src/pages/dashboard/MigrationDetail'));
const AllInOne = lazy(() => import('src/pages/dashboard/AllInOne'));
const ConfirmOrder = lazy(() => import('src/pages/dashboard/ConfirmOrder'));
const PaymentSuccess = lazy(() => import('src/pages/dashboard/PaymentSuccess'));
const CustomMigration = lazy(() => import('src/pages/dashboard/CustomMigration'));
const ConfirmCustomMigration = lazy(() => import('src/pages/dashboard/ConfirmCustomMigration'));
const Checkout = lazy(() => import('src/pages/dashboard/Checkout'));
const ConfirmCheckout = lazy(() => import('src/pages/dashboard/ConfirmCheckout'));
const Welcome = lazy(() => import('src/pages/dashboard/Welcome'));
const LoginSso = lazy(() => import('src/pages/dashboard/LoginSso'));

export const dashboardRoutes = [
   {
      path: '/',
      element: (
         <DashboardLayout>
            <Suspense>
               <Outlet />
            </Suspense>
         </DashboardLayout>
      ),
      children: [
         {
            index: true,
            element: <HomePage />,
         },
         {
            path: 'profile',
            element: <MyProfile />,
         },
         {
            path: 'payment',
            element: <MyProfile />,
         },
         {
            path: 'orderHistory',
            element: <MyProfile />,
         },
         {
            path: 'create-migration',
            element: <CreateMigration />,
         },
         {
            path: 'create-migration/:migration_id',
            element: <CreateMigration />,
         },
         {
            path: 'create-migration/:migrationType',
            element: <CreateMigration />,
         },
         {
            path: 'remigrate/:migration_id',
            element: <CreateMigration />,
         },
         {
            path: 'configuration',
            element: <Configuration />,
         },
         {
            path: 'configuration/:migration_id',
            element: <Configuration />,
         },
         {
            path: 'migration',
            element: <DemoMigration />,
         },
         {
            path: 'migration/:migration_id',
            element: <DemoMigration />,
         },
         {
            path: 'demo-running',
            element: <DemoRunning />,
         },
         {
            path: 'demo-running/:migration_id',
            element: <DemoRunning />,
         },
         {
            path: 'full-migration',
            element: <FullMigration />,
         },
         {
            path: 'full-migration/:migration_id',
            element: <FullMigration />,
         },
         {
            path: 'complete-migration',
            element: <CompleteMigration />,
         },
         {
            path: 'complete-migration/:migration_id',
            element: <CompleteMigration />,
         },
         {
            path: 'migration-detail',
            element: <MigrationDetail />,
         },
         {
            path: 'migration-detail/:migration_id',
            element: <MigrationDetail />,
         },
         {
            path: 'confirm-recent',
            element: <ConfirmMigration />,
         },
         {
            path: 'confirm-recent/:migration_id',
            element: <ConfirmMigration />,
         },
         {
            path: 'confirm-smart',
            element: <ConfirmMigration />,
         },
         {
            path: 'confirm-smart/:migration_id',
            element: <ConfirmMigration />,
         },
         {
            path: 'all-in-one-migration',
            element: <AllInOne />,
         },
         {
            path: 'confirm-order',
            element: <ConfirmOrder />,
         },
         {
            path: 'confirm-order/:service_type',
            element: <ConfirmOrder />,
         },
         {
            path: 'payment-success',
            element: <PaymentSuccess />,
         },
         {
            path: 'custom-migration',
            element: <CustomMigration />,
         },
         {
            path: 'confirm-custom-migration',
            element: <ConfirmCustomMigration />,
         },
         {
            path: 'check-out',
            element: <Checkout />,
         },
         {
            path: 'check-out/:checkout_token',
            element: <Checkout />,
         },
         {
            path: 'confirm-check-out',
            element: <ConfirmCheckout />,
         },
         {
            path: 'welcome',
            element: <Welcome />,
         },
         {
            path: '/sso/login/',
            element: <LoginSso />,
         },
      ],
   },
];
