import { Typography } from '@mui/material';
import React from 'react';
import DefaultSlide from 'src/pages/dashboard/CustomComponents/DefaultSlide';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

function CompleteItem(props) {
   const { index, isFinish } = props;

   const renderIcon = () => {
      if (isFinish) return <CheckCircleIcon color='primary' />;
      return <RadioButtonUncheckedIcon color='primary' />;
   };

   return (
      <React.Fragment>
         <DefaultSlide index={index} transition={50}>
            <Typography variant='subtitle2' sx={{ display: 'flex' }}>
               {renderIcon()}
               Migrating Completed
            </Typography>
         </DefaultSlide>
      </React.Fragment>
   );
}

export default CompleteItem;
