const shift4ShopPublicKey = '8aaba3152e00b5be5dc8f786541f9b48';

const migration_list_bannber_id = 1;
const migration_setup_banner_id = 2;
const all_in_one_banner_id = 3;
const custom_migration_banner_id = 3;

const reCalculatedCheckboxes = [
   'select_products',
   'select_customers',
   'select_orders',
   'select_blog_posts',
   'option_sel_urls',
   'option_seo_301',
   'option_language',
   'option_password',
   'option_multithread',
];

const basicFieldsAIO = [
   { checkbox: 'select_products', input: 'number_of_products', label: 'Products' },
   { checkbox: 'select_customers', input: 'number_of_customers', label: 'Customers' },
   { checkbox: 'select_orders', input: 'number_of_orders', label: 'Orders' },
   { checkbox: 'select_categories', input: '', label: 'Product Categories' },
   { checkbox: 'select_manufactures', input: '', label: 'Manufactures' },
   { checkbox: 'select_taxes', input: '', label: 'Taxes' },
   { checkbox: 'select_cms_pages', input: '', label: 'Pages' },
   { checkbox: 'select_blog_posts', input: 'number_of_blog_posts', label: 'Blog Posts' },
];

const entityFields = [
   'products',
   'reviews',
   'categories',
   'taxes',
   'manufacturers',
   'customers',
   'orders',
   'pages',
   'blogs',
   'coupons',
];

const lightWarningColor = '#ffebaf';
const darkWarningColor = '#7b631c';

export {
   shift4ShopPublicKey,
   migration_list_bannber_id,
   migration_setup_banner_id,
   all_in_one_banner_id,
   custom_migration_banner_id,
   reCalculatedCheckboxes,
   basicFieldsAIO,
   lightWarningColor,
   darkWarningColor,
   entityFields,
};
